const switchSummary = function(el, direction) {
  let id = parseInt($(el).closest('.summary').attr('id').replace('summary', ''))

  if (!isNaN(id)) {
    $(`#summary${id}`).hide()
    $(`#summary${id + direction}`).show()
  }
}

$(document).on('click', '.previous_summary', function() {
  switchSummary(this, 1)
})

$(document).on('click', '.next_summary', function() {
  switchSummary(this, -1)
})
