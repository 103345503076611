import '../css'
import { setCountryDivisionOptions } from '#/layouts/js/init'

$(document).on('change chosen:updated', '#generic_request_country_id', setCountryDivisionOptions)

$(document).on('show.bs.modal', '#address_verification_modal', function() {
  let line1 = $('#generic_request_line1').val()
  let secondInfo = $('#generic_request_postal_code').val()

  if (secondInfo == '') {
    let ary = [$('#generic_request_municipality').val(), $('#generic_request_country_division_id :selected').text()]
    secondInfo = ary.filter(s => s != '').join(',')
  }

  $('#user_input').val([line1, secondInfo].filter(s => s != '').join(','))

  let countryCode = {
    'UNITED STATES': 'USA',
    'CANADA': 'CAN',
    'UNITED KINGDOM': 'GBR',
    'AUSTRALIA': 'AUS'
  }[$('#generic_request_country_id :selected').text()]

  if (countryCode) {
    $(this).find('button .flag').hide()
    $(this).find(`button .flag_${countryCode}`).show()
    $(this).find('input[name=data_id]').val(countryCode)
  }
})

let initialDefaultCountry

$(function() {
  initialDefaultCountry = $('input[name="data_id"]').val()
})


$(document).on('shown.bs.modal', '#address_verification_modal', function() {
  $('#user_input').trigger('focus').trigger('select')
})

$(document).on('hide.bs.modal', '#address_verification_modal', function() {
  $('#user_input').val('')
  $(`#country_select.dropdown-menu li a[data-country="${initialDefaultCountry}"]`).trigger('click')
  $('#address_verification_results').html('')
  $('#generic_request_country_id').trigger('chosen:updated')
})

$(document).on('click', '#country_select.dropdown-menu li a', function() {
  let country_code = $(this).attr('data-country')
  let modal = $('#address_verification_modal')

  modal.find('button .flag').hide()
  modal.find(`button .flag_${country_code}`).show()
  modal.find('input[name="data_id"]').val(country_code)
})

$(document).on('keypress', 'input.address_search_field', function(e)
{
  if (e.key == 'Enter') {
    e.preventDefault()
    $('#address_verification_submit_button').trigger('click')
  }
})

$(document).on('click', '#address_verification_submit_button', function() {
  $('#address_verification_results').html('Searching ...')
})

$(document).on('click', '.picklist_item', function() {
  let form = $('form#address_refiner')
  form.find('input[name=user_input]').val('')
  form.find('input[name=moniker]').val($(this).attr('data-moniker'))
  form.find('input[name=display]').val($(this).attr('data-display'))
  form.find('input[name=postcode]').val($(this).attr('data-postcode'))
  form.find('input[name=score]').val($(this).attr('data-score'))
  form.find('#submitButton').trigger('click')
})

$(document).on('keypress', '#address_verification_results', function(e) {
  if (e.key != 'Enter') return

  if ($('#user_input:focus')) {
    $('#submitButton').trigger('click')
  } else {
    e.preventDefault()
  }
})

$(document).on('modal:hide', '#address_verification_modal', function() {
  $(this).modal('hide')
})
