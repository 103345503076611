const lookupProgram = function() {
  let container = $('#program_ending_literature #lookup-program')
  if (container.length == 0) return

  let programCode = $('#program_detail_program_code').val()
  if (programCode == '') return

  $('#refresh_program_info').addClass('disabled').attr('disabled', 'disabled')

  $.get(`/programs/${programCode}`, function(data) {
    if (!data) {
      alert('Error: Program could not be loaded, please verify that program exists in Cedar')
    } else {
      $('#program_detail_program_title').val(data['title'])
      if (data['products']) {
        $.each(data['products'], function(index, value) {
          $('#program_ending_literature').append(`<strong>${value['code']}</strong> - ${value['name']}<br>`)
        })
      }
    }
    $('#refresh_program_info').removeClass('disabled').removeAttr('disabled')
  })
}

$(document).on('change', '#program_detail_program_code', lookupProgram)
$(lookupProgram)
