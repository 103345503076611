$(document).on('click', '#programTab a', function(e) {
  e.preventDefault()
  $(this).tab('show')
})

$(function() {
  if ($('.ring-in-time-group .fa-up-down').length > 0) {
    $('.ring-in-time-group').sortable({
      containment: 'parent',
      axis: 'y',
      handle: '.fa-up-down',
      update: function(e, ui) {
        var ids = ui.item.parent().children().map(function(i, el) { return el.getAttribute('data-id') }).get()
        $.ajax({
          method: 'POST',
          url: '/supervisor/ring_in_times/reorder',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          },
          data: { ids: ids }
        })
      }
    })
  }
})
