import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'

import 'bootstrap-sass'

import '@fortawesome/fontawesome-free/css/all.css'

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
select2(window, $)

import 'chosen-js'
import 'chosen-js/chosen.css'

import Cocooned from '@notus.sh/cocooned'
Cocooned.start()

// jquery-ui must be imported in this file to ensure it's loaded after jquery is added to window
import 'jquery-ui/ui/data'
import 'jquery-ui/ui/scroll-parent'
import 'jquery-ui/ui/version'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/sortable'

import '#/layouts/js'
import '#/address_verifications/js'
import '#/generic_requests/js'
import '#/literature_lists/js'
import '#/operators/js'
import '#/pages/js'
import '#/schedules/js'
import '#/admin/regions/js'
import '#/supervisor/operators/js'
import '#/supervisor/program_details/js'
import '#/supervisor/reports/js'
import '#/supervisor/ring_in_times/js'
