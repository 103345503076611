import 'bootstrap-3-typeahead'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'
import 'chosen-js'
import 'chosen-js/chosen.css'

const initTypeahead = function() {
  $('.typeahead').typeahead({
    items: 'all',
    highlight: true,
    minLength: 0,
    autoSelect: false,
    showHintOnFocus: true,
    source: ['ATTN: ', 'ATTN: Waiting Room', 'C/O: ']
  })
}

const initChosen = function() {
  $('.chzn-select').chosen()
  $('.chzn-select-deselectable').chosen({ allow_single_deselect: true })
}

const select2sorter = function(results) {
  if (!results || results.length == 0) return results

  let term = $('.select2-search__field').val().toLowerCase()
  if (term.length == 0) return results

  return results.sort(function(a, b) {
    let aHasPrefix = a.text.toLowerCase().indexOf(term) == 0
    let bHasPrefix = b.text.toLowerCase().indexOf(term) == 0

    return bHasPrefix - aHasPrefix // If one is prefixed, push to the top. Otherwise, no sorting.
  })
}

const initSelect2 = function() {
  $('select.select2').select2({
    theme: 'bootstrap',
    sorter: select2sorter
  })
}

const initDatepicker = function() {
  $('.datepickerComponent').datepicker({
    format: 'mm/dd/yyyy',
    forceParse: false,
    todayHighlight: true
  }).on('changeDate', function() {
    $('.datepickerComponent').datepicker('hide')
  })
}

const setCountryDivisionOptions = function() {
  let select = $('#generic_request_country_division_id')
  if (select.length == 0) return
  select.empty()

  let countryId = $('#generic_request_country_id').val()
  if (countryId == '') return

  $.get(`/address_verification/country_division_select/${countryId}`, function(data) {
    select.append(data).val(select.attr('data-id')).trigger('chosen:updated')
  })
}

const initEverything = function() {
  initTypeahead()
  initChosen()
  initSelect2()
  initDatepicker()
  setCountryDivisionOptions()
  $('.focusField').trigger('focus')
}

export { initEverything, setCountryDivisionOptions }
