import '../css'
import ujs from '@rails/ujs'
import { initEverything } from './init'

$(function() {
  initEverything()

  // Give UJS driver a chance to load
  var form = document.querySelector('.search-on-load')
  if (form) {
    ujs.fire(form, 'submit')
    $(form).find('input[name="page"]').remove() // Remove saved page number so it doesn't pollute subsequent searches
  }
})

$(document).on('click', '.callout', function() {
  let visible = $(this).next('.content').is(':visible')
  $(this).next('.content').toggle(!visible)
  $(this).find('.caret-down').toggle(!visible)
  $(this).find('.caret-right').toggle(visible)
})

$(document).on('click', 'td.expansion', function() {
  $(this).closest('tr.main-row').next('.expansion-row').fadeToggle('fast')
})
