import '../css'

$(document).on('click', '#expand_pre_ring_in_times', function(e) {
  e.preventDefault()
  $(this).hide()
  $('#collapse_pre_ring_in_times, #pre_ring_in_times').show()
})

$(document).on('click', '#collapse_pre_ring_in_times', function(e) {
  e.preventDefault()
  $(this).hide()
  $('#expand_pre_ring_in_times').show()
  $('#pre_ring_in_times').hide()
})

$(document).on('click', '#expand_post_ring_in_times', function(e) {
  e.preventDefault()
  $(this).hide()
  $('#collapse_post_ring_in_times, #post_ring_in_times').show()
})

$(document).on('click', '#collapse_post_ring_in_times', function(e) {
  e.preventDefault()
  $(this).hide()
  $('#expand_post_ring_in_times').show()
  $('#post_ring_in_times').hide()
})

$(document).on('submit', '.default-schedule-form', function() {
  $('.time-section:not(.selected) .destroy-field').val('true')
})

$(document).on('click', '.time-section', function() {
  $(this).toggleClass('selected')
})
