import '../css'

$(document).on('change', '#signin-report-options select', function() {
  $.ajax({
    method: 'GET',
    url: '/supervisor/reports/last_signed_in?region=' + $(this).val(),
    dataType: 'script'
  })
})

$(document).on('click', '.time-section-report', function() {
  $(this).find('.operator_names').toggle()
})

$(document).on('change', '#operator_time_zone', function() {
  let zone = $(this).val()
  if (zone == '') return

  $('.time_zones').each(function() {
    if ($(this).text() == zone) {
      $(this).remove()
      return
    }
  })

  $('#time_zone_list_div').append(`<div class="time_zones">${zone}</div>`)
})

$(document).on('click', '#retrieveButton', function(e) {
  e.preventDefault()

  let button = $(this)
  let results = $('#results')

  button.addClass('disabled').attr('disabled', 'disabled').html('Retrieving...')
  results.html('')

  let typeArray = $('input[name="type"]:checked').map(function() { return this.value }).get()
  let statusArray = $('input[name="status"]:checked').map(function() { return this.value }).get()

  if (typeArray.length == 0 || statusArray.length == 0) {
    results.html('Error: Please select at least one Operator Type and one Operator Status.')
    button.removeClass('disabled').removeAttr('disabled').html('Retrieve List')
    return
  }

  let only_trainer = $('#is_trainer').is(':checked')
  let time_zones = $('.time_zones').map(function() { return this.innerText }).get()

  $.ajax({
    method: 'POST',
    url: '/supervisor/reports/email_list_retrieve',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    data: {
      types: typeArray,
      statuses: statusArray,
      only_trainer: only_trainer,
      time_zones: time_zones
    }
  }).done((data) => {
    if (data == '') {
      results.html('No results found.')
    } else {
      results.html(data)
    }

    button.removeClass('disabled').removeAttr('disabled').html('Retrieve List')
  })
})
