$(document).on('click', '.add_note', function() {
  let id = $(this).prevAll('#operator_id').val()
  let fullname = $(this).prevAll('#fullname').val()
  let modal = $('#newNoteModal')
  modal.find('.modalFullname').html(fullname)
  modal.find('.modalOperatorId').val(id)
  modal.modal('show')
})

$(document).on('hide.bs.modal', '#newNoteModal', function() {
  $(this).find('.modalFullname').html('')
  $(this).find('.modalOperatorId').val('')
  $(this).find('.modalContent').val('')
  $(this).find('.errorMessage').html('').removeClass('alert alert-error')
})

$(document).on('shown.bs.modal', '#newNoteModal', function() {
  $('.modalContent').trigger('focus')
})

const toggleTrainerDiv = function() {
  if ($.inArray($(this).val(), ['supervisor', 'call_center', 'student_call_center', 'sunday_student']) >= 0) {
    $('#trainer-div').show()
  } else {
    $('#operator_is_trainer').prop('checked', false)
    $('#trainer-div').hide()
  }
}

$(document).on('change', '#operator_operator_type', toggleTrainerDiv)
$(toggleTrainerDiv)
