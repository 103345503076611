

const toggleVisitRequestDiv = function() {
  $('#visit_request_div').toggle($('#generic_request_has_visit_request').is(':checked'))
}

$(document).on('change', '#generic_request_has_visit_request', toggleVisitRequestDiv)

$(document).on('change', '#generic_request_wants_other_toggle', function() {
  if($(this).is(':checked')) {
    $('#wants_other_div').show()
  } else {
    $('#generic_request_wants_other').val('')
    $('#wants_other_div').hide()
  }
})

$(document).on('change', '#generic_request_is_former_wcg', function() {
  if($(this).is(':checked')) {
    $('#is_baptized_div').show()
  } else {
    $('#wants_other_div').prop('checked', false)
    $('#is_baptized_div').hide()
  }
})

$(document).on('click', '#vr_lit_toggle_all', function() {
  let checked = $('#vr_lit_all_checked').val() == 'false'
  $('.vr_lit').prop('checked', checked)
  $('#vr_lit_all_checked').val(checked.toString())
  $('#vr_lit_toggle_all').html(`${checked ? 'Uncheck' : 'Check'} All`)
})

$(document).on('change', '.address_field', function() {
  $('#generic_request_is_verified').val('false')
})

$(document).on('change', '#generic_request_promo_code_id', function() {
  let request_origin_id = $(this).find(':selected').attr('data-request-origin-id')
  $('#generic_request_request_origin_id').val(request_origin_id).trigger('chosen:updated')
})

$(document).on('change', '#generic_request_request_origin_id', function() {
  $('#generic_request_promo_code_id').val('').trigger('chosen:updated')
})

$(document).on('change', '#product_options', function() {
  let id = $(this).find(':selected').val()
  if (id == '') return

  if ($('#selected_products').find(`#generic_request_product_ids_${id}`).length > 0) {
    alert('Product already in the list.')
    return
  }

  let name = $(this).find(':selected').text()

  let div = `
    <div class="removeable row row-skinny row-bottom-space">
      <div class="col-md-12">
        <a href="#" class="remove btn btn-xs btn-danger"><i class="fas fa-xmark"></i></a> ${name}
        <input id="generic_request_product_ids_${id}" name="generic_request[product_ids][]" type="hidden" value="${id}">
      </div>
    </div>`
  $('#selected_products').append(div)
  $(this).val().trigger('chosen:updated')
})

$(document).on('click', 'a.remove', function(e) {
  e.preventDefault()
  $(this).closest('.removeable').remove()
})

$(toggleVisitRequestDiv)
