import Tipped from '@staaky/tipped'
import '@staaky/tipped/dist/css/tipped.css'

import { initEverything } from '#/layouts/js/init'

const minimizeOrderPane = function() {
  $('#order').removeClass('pane-full').removeClass('col-md-12')
  $('#order').addClass('pane-left').addClass('col-md-6')
  $('#open_info').hide()
  $('#close_info').show()
  $('#info').show()
}

$(document).on('click', '#close_info a', function(e) {
  e.preventDefault()
  $('#order').removeClass('pane-left col-md-6').addClass('pane-full col-md-12')
  $('#info').hide()
  $('#close_info').hide()
  $('#open_info').show()
})

$(document).on('click', '#open_info a', function(e) {
  e.preventDefault()
  minimizeOrderPane()
})

$(document).on('click', '.info_pane_link', function() {
  if (!$('#info').is(':visible')) {
    minimizeOrderPane()
  }
  $('.info_pane').hide()
  $(`#${$(this).attr('id')}`).show()
})

let formContent = ''

$(function() {
  Tipped.create('.simple-tooltip', {
    size: 'large',
    position: 'right'
  })

  let container = $('#order-pane')
  if (container.length > 0) {
    container.html('Loading...')
    $.get(container.attr('data-url'), function(data) {
      formContent = data
      container.html(data)
      initEverything()
    }).fail(function() {
      container.html('Error: Please reload the site.')
    })
  }
})

$(document).on('click', '.reset-form', function(e) {
  e.preventDefault()
  $('#order-pane').html(formContent)
  initEverything()
})
